import React from "react";
import { Menu, Drawer } from 'antd';
import logo from '../../assets/images/logos/college-logo.png';
import aicte from '../../assets/images/AICTE.png';
import styles from './header.module.css'
import { useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';


function AppHeader(props) {
	const [openDrawer, setOpenDrawer] = useState(false);
	const { selectedNavLink, setSelectedNavLink } = props;

	return (
		<div>
			<div className={styles.logoOuterContainer}>
				<div className={styles.logoInsideContainer}>
					<img src={logo} alt="Logo" className={styles.logoStanford}/>
					<div className={styles.collegeName}>
						<p className={styles.textStanford}>Stanford</p>
						<p className={styles.textSchool}>Business School</p>
					</div>
					<div className={styles.aicteContainer}>
						<img src={aicte} alt="AICTE logo" className={styles.logoAicte}/>
						<p className={styles.textAicte}>AICTE APPROVED</p>
					</div>
				</div>
				
				<div className={styles.drawerIcon}>
					<MenuOutlined onClick={() => { setOpenDrawer(true)} } />
				</div>
				
				<Drawer
					open={openDrawer}
					onClose={() => {
						setOpenDrawer(false);
					}}  
					closable={true} 
					width="280"
					bodyStyle={{
						border: "none"
					}}>
					<MenuItems
						isInline
						setOpenDrawer={setOpenDrawer}
						selectedNavLink={selectedNavLink}
						setSelectedNavLink={setSelectedNavLink}
					/>
				</Drawer>
			</div>

			<div className={styles.navContainer + " header"}>
					<MenuItems
						selectedNavLink={selectedNavLink}
						setSelectedNavLink={setSelectedNavLink}
					/>
				</div>
		</div>
	)
}

function MenuItems(props) {
	const mode = props.isInline ? 'inline' : 'horizontal';
	// const path = window.location.pathname.slice(1) || 'home';
	const setOpenDrawer = props.setOpenDrawer;
	const { selectedNavLink, setSelectedNavLink } = props;

	const handleMenuChange = (e) => {
		// Will only be received in "inline" mode
		if(setOpenDrawer) setOpenDrawer(false);

		setSelectedNavLink(e.key);
		window.scrollTo({ top: 0, behavior:'smooth'})
	}
	return (
		<>
		{/* <span>{selectedNavLink}</span> */}
		<Menu
			mode={mode}
			defaultSelectedKeys={[selectedNavLink]}
			bodyStyle={{border: "none"}}
			onClick={handleMenuChange}
			selectedKeys={[selectedNavLink]}
			className={styles.menu}
		>
				<Menu.Item key="home">
					<Link to="/">Home</Link>				
				</Menu.Item>

				<Menu.Item key="vision">
						<Link to="/vision">Vison & Mission</Link>	
				</Menu.Item>
				
				<Menu.Item key="programs">
						<Link to="/programs">Programmes</Link>	
				</Menu.Item>
				
				<Menu.Item key="mentors">
						<Link to="/mentors">Academic Mentors</Link>	
				</Menu.Item>
				
				<Menu.Item key="about">
					<Link to="/about">About</Link>
				</Menu.Item>
				
				<Menu.Item key="contact">
						<Link to="/contact">Contact</Link>	
				</Menu.Item>
		</Menu>
		</>
	)
}

export default AppHeader;