import React from "react";

const Mentors = () => {
  return (
		<div className="container-fluid">
			<h1>Mentors</h1>
			<p>Available soon...</p>
		</div>
	)
}

export default Mentors;