import './App.css';
// import './App-custom.css';
// import Navbar from './components/Navbar/Navbar';
// import { AppConfigContext } from 'antd/es/app/context';
import { Layout } from 'antd';
import AppHeader from './components/common/header';
import Footer from './components/common/footer.js'
import Homepage from './components/Views/Homepage/Homepage';
import Vision from './components/Views/Vision/Vision';
import Programs from './components/Views/Programs/Programs';
import Mentors from './components/Views/Mentors/Mentors';
import About from './components/Views/About/About';
import Contact from './components/Views/Contact/Contact';

import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useState } from 'react';

const { Header, Content } = Layout;

const router = createBrowserRouter([
  {
    path: '/',
    element: <Homepage />
  },
  {
    path: '/vision',
    element: <Vision />
  },
  {
    path: '/programs',
    element: <Programs />
  },
  {
    path: '/mentors',
    element: <Mentors />
  },
  {
    path: '/about',
    element: <About />
  },
  {
    path: '/contact',
    element: <Contact />
  }
]);

function App() {
  const path = window.location.pathname.slice(1) || 'home';
  console.log("current selected in App.js", path)
  const [selectedNavLink, setSelectedNavLink] = useState(path);
 
  return (
    <BrowserRouter>
      <Layout className="mainLayout">
        <Header>
          {/* <div style={{position: "fixed", top:"0px", fontSize:"2rem"}}>{selectedNavLink}</div> */}
          <AppHeader selectedNavLink={selectedNavLink} setSelectedNavLink={setSelectedNavLink}/>
        </Header>
        <Content>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/vision" element={<Vision />} />
              <Route path="/programs" element={<Programs />} />
              <Route path="/mentors" element={<Mentors />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
        </Content>
        <Footer setSelectedNavLink={setSelectedNavLink} />
      </Layout>
    </BrowserRouter>
  );
}

export default App;
