import React from "react";
import styles from './vision.module.css';

const Vision = () => {
  return (
		<div className={"container-fluid " + styles.visionContainer}>
			<h1>Vision</h1>
			<h3>"Empowering Growth through Education"</h3>
			<p>Our vision for the future is to transform Stanford into a business school that exemplifies excellence on a global scale. Just as the most renowned institutions worldwide, we aspire to be the beacon of critical thinking and social responsibility in the Indian business education landscape. At Stanford, we envision a dynamic hub of cutting-edge research that directly influences both our educational initiatives and our strong ties with the corporate world.</p>

			<h3><strong>Specifically, our goal is to:</strong></h3>

			<ul>
				<li>With the help of exceptionally talented faculty who generate pioneering knowledge that resonates with the business community and society, disseminate their knowledge through impactful publications in prestigious scientific journals and by engaging closely with corporate partners.</li>
				<li>Welcome highly motivated students to an enriching, cross-cultural learning environment that offers research-based expertise across various programs and executive education. We are committed to nurturing their professional skills through collaboration with industry leaders.</li>

			</ul>

			<h1 className={styles.headingMission}>Mission:</h1>
			<p>The Stanford Business School is dedicated to cultivating well-researched and pragmatic business practices that equip our students to conquer the challenges of an ever-changing business landscape in the new global economic era. Our mission revolves around instilling conceptual and practical competencies in our students, transforming their abilities into adept managerial skills essential in today's fiercely competitive environment.</p>

			<p>Here, we firmly believe in the perfect fusion of relevant knowledge, honed skills, and the right attitude, as this blend is the bedrock of our students' comprehensive development, paving their path to growth and triumph in the corporate realm.</p>

		</div>
	)
}

export default Vision;