import React from "react";
import styles from './contact.module.css';
import { Space } from 'antd';
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';

const Contact = () => {
  return (
		<div className={"container-fluid " + styles.contactContainer}>
			<Space direction="vertical">
				<h1>Contact</h1>
				<div className={styles.cardContainer}>
					<div className={styles.iconPane}><HiOutlineLocationMarker /></div>
					<div className={styles.mainContent}>
						Urban Estate, Phase-I <br/>
						Jalandhar, Punjab <br/>
						PIN: 144022
					</div>
				</div>

				<div className={styles.cardContainer}>
					<div className={styles.iconPane}><AiOutlinePhone /></div>
					<div className={styles.mainContent}>
						+91 786 786 3700 <br/>
						+91 98883 83183 <br/>
					</div>
				</div>

				<div className={styles.cardContainer}>
					<div className={styles.iconPane}><AiOutlineMail /></div>
					<div className={styles.mainContent}>
						stanford1business@gmail.com
					</div>
				</div>


				{/* <Card title="Contact">
					<p>Phone: +91 xxxxx-xxxxx</p>
					<p>Phone: +91 xxxxx-xxxxx</p>
					<p>Email: stanfordbschool@gmail.com</p>
				</Card> */}
			</Space>
		</div>
	)
}

export default Contact;