import React from "react";
import styles from './Footer.module.css';
import { Row, Col, Space, Button } from 'antd';
import { Link } from 'react-router-dom'

const Footer = (props) => {
	const { selectedNavLink, setSelectedNavLink } = props;

	const handleNavLinkChange = (key) => {
		setSelectedNavLink(key)
		window.scrollTo({ top: 0, behavior: 'smooth'})
	}	

	return (
		<div className={styles.footerBackground}>
			<div className={styles.footerContainer}>
				<Row style={{width: "100%"}}>
					<Col xs={24} sm={8} md={8} lg={8} xl={8} className={styles.col}>
						{/* ----- Links ------ */}
						<Space direction="vertical" className={styles.linksContainer}>
							<h2>Quick links</h2>
							
							{/* "handleNavLinkChange("about") should be passed the same as key of Menu.Items in header */}

							<Button
								type="text"
								className={styles.linkBtn}
								onClick={(e) => handleNavLinkChange("about") }
							>
								<Link to="/about">About</Link>
							</Button>

							<Button
								key="programs"
								type="text"
								className={styles.linkBtn}
								onClick={(e) => handleNavLinkChange('programs')}
							>
								<Link to="/programs">Programmes</Link>
							</Button>
							
							<Button
								key="vision"
								type="text"
								className={styles.linkBtn}
								onClick={(e) => handleNavLinkChange('vision')}
							>
								<Link to="/vision">Vision & Mission</Link>
							</Button>
							
							<Button
								key="contact"
								type="text"
								className={styles.linkBtn}
								onClick={(e) => handleNavLinkChange('contact')}
							>
								<Link to="/contact">Contact</Link>
							</Button>
						</Space>
					</Col>

					<Col xs={24} sm={8} md={8} lg={8} xl={8} className={styles.col}>
						{/* ------ Address -------- */}
						<Space direction="vertical" className={styles.address}>
							<h2>Address</h2>
							<p>Urban Estate, Phase-I</p>
							<p>Jalandhar, Punjab</p>
							<p>Pin: 144022</p>
						</Space>
					</Col>

					<Col xs={24} sm={8} md={8} lg={8} xl={8} className={styles.col}>
						{/* ------ Contact -------- */}
						<Space direction="vertical" className={styles.contact}>
							<h2>Contact</h2>
							<p>Phone: +91 786 786 3700</p>
							<p>Phone: +91 98883 83183</p>
							<p>Email: <a href="mainto:stanford1business@gmail.com">stanford1business@gmail.com</a></p>
						</Space>
					</Col>

				</Row>
				<Row>
					<Col className={styles.myMailCol}>
						<p>
							Website designed & developed by <a href="mailto:Harshsahni1068@gmail.com" className={styles.mailLink}>Harsh Sahni</a> <a href="mailto:Harshsahni1068@gmail.com">(<abbr title="harshsahni1068@gmail.com">email</abbr>)</a>
						</p>
					</Col>
				</Row>

			</div>
		</div>
	)
}

export default Footer;