import React from 'react';
import { Typography, Row, Col } from 'antd';
import styles from './about.module.css';
import '../../../assets/fonts/fonts.css'

const { Title, Paragraph } = Typography;

const About = () => {
  return (
    <div className={styles.aboutContainer}>
      <div className="container-fluid ">
        <Row>
          <Col xs={24} sm={24} md={24} lg={20}>
            <div className={styles.aboutLeft}>
              <Title level={2} className={styles.title}>About Stanford Business School</Title>
              
              <Paragraph>
                <span className={styles.sectionHeading}>Academic Excellence and Global Recognition:</span>
                <p>
                  As the promoting organization is entering its 40th glorious year in education sector, the seed to start a world class business management institution has been laid down with the start of Stanford Business School in Jalandhar, recognized by All India Council of Technical Education, New Delhi.
                </p>
                <p>
                  With a focus on research, entrepreneurship, and innovation, we attract highly-qualified faculty and talented students from diverse backgrounds. Our academic programs are purposeful and professionally oriented, providing an internationally recognized qualification.
                </p>
              </Paragraph>
              <Paragraph>
                <span className={styles.sectionHeading}>Industry Partnership and Practical Exposure:</span>
                <p>
                  Our strong industry–business school interface integrates classroom teaching with practical experience in the field, bridging the gap between theory and practice. We offer internships and job placements, ensuring our students are well-prepared for the ever-evolving business landscape.
                </p>
              </Paragraph>
              <Paragraph>
                <span className={styles.sectionHeading}>Global Network and Collaborations:</span>
                <p>
                  With upcoming international tie-ups with renowned institutions providing training in business management, we offer globally recognized management education. Our partnerships with industry giants will provide unique opportunities for our students in entrepreneurship and scaling up new ventures.
                </p>
              </Paragraph>
              <Paragraph>
                <span className={styles.sectionHeading}>Join Us on the Path of Excellence:</span>
                <p>
                  At Stanford Business School, we welcome students from all walks of life to join us on a journey of academic excellence, personal growth, and social impact. With a vibrant student exchange program and a culture of openness, participation, and fairness, we strive to create future leaders who will drive positive change in society and beyond. Our commitment to technology-enabled education through MOOCs and online platforms ensures that learning knows no bounds.
                </p>
              </Paragraph>
              <Paragraph>
                <span className={styles.sectionHeading}>Step into the World of Opportunities:</span>
                <p>
                  With a strong emphasis on personality development, grooming, and communication skills, we prepare our students to enter the business world with determination and self-confidence. Our focus on nurturing a scientific spirit of inquiry ensures they become leaders, changemakers, and responsible citizens, ready to shape the world of business and beyond.
                </p>
              </Paragraph>
              <Title level={4}>Stanford Business School - Where Growth Meets Education.</Title>
            </div>
          </Col>
          <Col span={0}>
            <div className={styles.aboutRight}>
              {/* Right section content (if any) goes here */}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default About;
