import React from "react";
import styles from './programs.module.css';
import { Row, Col, Space } from 'antd';
import {
	PiGraduationCapBold,
	PiCalendarDuotone,
	PiNotebookBold
} from 'react-icons/pi';

const allPrograms = getProgramsDetails();

const Program = () => {
	const imagesPath = '/images/programs/';
  return (
		<div className="container-fluid">
			<h1>DEGREE-GRANTING PROGRAMMES</h1>
			<p className={styles.topMsg}>English is the medium of instruction for all programmes offered by the school. We offers the following academic programmes</p>
			<div className={styles.programsContainer}>
				{allPrograms.map((program, i) => (
					<>
					<h2>{i+1}. {program.title}</h2>
						<div className={styles.rowOuterDiv}>
					<Row className={styles.row} data-key={program.key}>

						<Col className={styles.colImage} xs={24} sm={10} md={10}>
							<img src={imagesPath + program.image} alt={program.image} />
						</Col>
						
						<Col className={styles.colInfo} xs={24} sm={14} md={14}>
								<Programme
									title={program.title}
									duration={program.duration}
									eligibility={program.eligibility}
									description={program.description}
								/>
						</Col>

					</Row>
					</div>
					</>
				))}
			</div>
		</div>
	)
}

const Programme = (props) => {
	const { title, duration, eligibility, description } = props;

	return (
		<div className={styles.card}>
			<Space direction="vertical">
				<h2>
					{/* <PiGraduationCapBold className={styles.icon}/> */}
					{title}
				</h2>
				<p className={styles.subHeadingContainer}>
					<PiCalendarDuotone
						className={styles.icon + " " + styles.iconDuration}/>
					<span>
						<span className={styles.subHeading}>
							Duration:
						</span>
						<span>{duration}</span>
					</span>

					<br className={styles.breakEl} />

					<PiNotebookBold
						className={styles.icon + " " + styles.iconEligibility}/>
					<span>
						<span className={styles.subHeading}>
							Eligibility:
						</span>
						<span>{eligibility}</span>
					</span>
				</p>

				<p className={styles.description}>{description}</p>
			</Space>

		</div>
	)


}

function getProgramsDetails() {
	return [
		{
			title: 'PGDM - HOSPITAL & HEALTHCARE MANAGEMENT',
			eligibility: 'Bachelor’s degree',
			duration: '2 years',
			image: 'health.jpg',
			key: 'healthcare',
			description: 'The PGDM Hospital & Healthcare Management program equips students with specialized skills to excel in the dynamic and critical healthcare industry. Through an intensive curriculum, students gain in-depth knowledge of healthcare systems, policy frameworks, and regulatory environments. Emphasis is placed on understanding the intricacies of managing healthcare facilities, optimizing patient care, and developing efficient operational strategies. Students delve into cutting-edge healthcare technologies and innovations, learning to leverage data-driven insights for informed decision-making. Additionally, they develop a deep understanding of healthcare economics, financial management, and reimbursement mechanisms. Practical experience is integrated through inhouse parent hospital and various industry collaborations, enabling students to tackle real-world challenges and enhance their leadership and problem-solving capabilities in this rapidly evolving sector.'
		},
		{
			title: 'PGDM - FINANCE & CAPITAL MARKETS',
			eligibility: 'Bachelor’s degree',
			duration: '2 years',
			image: 'finance.png',
			key: 'finance',
			description: 'The Finance & Capital Markets program offers a rigorous and comprehensive curriculum designed to groom finance professionals for success in the fast-paced world of global finance. Students explore advanced financial theories, risk management strategies, and asset valuation techniques, preparing them to navigate complex financial markets with confidence. The program emphasizes practical application through hands-on experiences such as trading simulations and portfolio management exercises. Students analyze case studies of real-world financial crises, gaining insights into decision-making under pressure. With a focus on mergers and acquisitions, capital raising, and investment banking, graduates are equipped to excel in diverse financial roles. Esteemed faculty and industry experts enrich the learning experience, enabling students to acquire the technical expertise and strategic acumen needed to thrive in the competitive finance landscape.'
		},
		{
			title: 'PGDM - MARKETING',
			eligibility: 'Bachelor’s degree',
			duration: '2 years',
			image: 'marketing.jpg',
			key: 'marketing',
			description: 'The Marketing program is tailored to foster innovative marketers capable of driving strategic growth in today\'s fiercely competitive markets. Covering both traditional and digital marketing aspects, students gain a comprehensive understanding of consumer behavior, market research, and branding. Rigorous training in marketing analytics equips students with the ability to make data-driven marketing decisions, optimize campaigns, and measure ROI effectively. The curriculum includes immersive projects with renowned companies, allowing students to tackle real marketing challenges and devise creative solutions. Emphasis is placed on understanding global marketing trends, cultural nuances, and ethical considerations. Graduates emerge as forward-thinking marketing professionals, adept at crafting impactful marketing strategies that resonate with diverse audiences and deliver measurable results.'
		},
		{
			title: 'PGDM - HR (HUMAN RESOURCES)',
			eligibility: 'Bachelor’s degree',
			duration: '2 years',
			image: 'hr.jpg',
			key: 'hr',
			description: 'The HR program prepares students to become strategic HR leaders capable of driving organizational excellence through talent management and employee development. The curriculum delves into key areas such as talent acquisition, performance management, and succession planning. Students explore the latest HR technologies, including HR analytics and talent management software, to enhance HR processes and decision-making. Practical exposure is facilitated through internships and industry projects, where students apply HR best practices in real-world settings. With a focus on fostering inclusive and diverse work cultures, students learn to create conducive environments for employee growth and well-being. Ethical considerations and legal aspects of HR management are also emphasized, equipping graduates to address complex HR challenges and contribute to the overall success of organizations.'
		},
		{
			title: 'PGDM - OPERATIONS AND SUPPLY CHAIN',
			eligibility: 'Bachelor’s degree',
			duration: '2 years',
			image: 'operations.jpg',
			key: 'operations',
			description: 'The Operations and Supply Chain program equips students with the skills to optimize supply chain networks and streamline business operations. The curriculum covers inventory management, logistics, and supply chain analytics, enabling students to enhance efficiency, reduce costs, and improve customer satisfaction. Emphasis is placed on understanding global supply chain dynamics and sustainability practices. Students engage in process improvement projects, utilizing methodologies like Six Sigma and Lean Management to optimize operations. The program fosters collaboration with industry partners, providing students with exposure to real operational challenges and fostering innovation. Graduates emerge as adept supply chain managers capable of designing agile and resilient supply chains that deliver a competitive advantage in today\'s interconnected world.'
		},
		{
			title: 'PGDM - DATA ANALYTICS & AI',
			eligibility: 'Bachelor’s degree',
			duration: '2 years',
			image: 'ai.png',
			key: 'ai',
			description: 'The Data Analytics & Artificial Intelligence program equips students with the technical expertise and analytical acumen to leverage data for strategic decision-making. The curriculum covers statistical analysis, machine learning algorithms, and big data technologies, empowering students to derive actionable insights from complex datasets. Students engage in real-world projects, collaborating with industry partners to solve business challenges using data-driven solutions. Ethical considerations in data privacy and AI implementation are explored to ensure responsible data usage. Graduates possess a strong foundation in programming, data visualization, and AI applications, making them sought-after data scientists and AI specialists capable of driving innovation and fostering a data-driven culture within organizations.'
		},
	];
	
}

export default Program;