import React from "react";
// import { Carousel } from "antd";
import styles from './Homepage.module.css';
import { Col, Row } from 'antd';
import { FiChevronsRight } from "react-icons/fi";

function Homepage() {

	const programs = [{
		title: 'PGDM - Hospital & Healthcare management',
		description: 'You’ll learn about healthcare systems, medical administration, healthcare policies, and how to manage healthcare facilities efficiently.'
	},{
		title: 'PGDM - Finance & Capital Markets',
		description: 'Here you will learn financial analysis, investment strategies, risk management, and how to navigate the complexities of capital markets.'
	},{
		title: 'PGDM - Marketing',
		description: 'In this program, students will learn marketing strategies, consumer behaviour, market research, and how to create effective marketing campaigns, etc.'
	},{
		title: 'PGDM - HR',
		description: 'Students will learn recruitment, talent management, employee relations, HR policies, and how to foster a positive work culture.'
	},{
		title: 'PGDM - Operations and Supply Chain',
		description: 'Students will learn about supply chain logistics, operations management, inventory control, and how to optimize business processes.'
	},{
		title: 'PGDM - Data Analytics & AI',
		description: 'Students will learn data analysis, machine learning, AI algorithms, data visualization, and how to derive valuable insights from data.'
	}]

	return (
		<>
		<div className={styles.homepage}>
			<div className={styles.campusImgCont}>
				<div className={styles.msg}>
					Stanford Business School 2 years PGDM programme in management approved by AICTE, New Delhi
				</div>
			</div>
			
			{/* Why Stanford */}
			<div className={styles.whyStanfordContainer + ' container-fluid'}>
				<Row>
					<Col xs={24} sm={24} md={24} lg={10}>
						<div className={styles.whyHeading}>
							<div>Why join Stanford <br className={styles.breakEl}/>Business School?</div>
							{/* <div>Standford</div> */}
							{/* <div>Business School?</div> */}
						</div>
					</Col>
					<Col xs={24} sm={24} md={24} lg={14}>
						<div className={styles.whyContent}>
							<div>
								Establishment of <strong style={{display: "inline"}}>Stanford Business School</strong> is by group of professionals who are well established in their field, having achieved various laurels at national and international level and are willing to give their knowledge & expertise to the students joining this institute teaching programs in business management. Promoters and faculty is confident of their rich experience and will <strong>enable the students to establish their own businesses</strong> or steer their employer's business to greater heights.
							</div>
						</div>
					</Col>
				</Row>

				<Row className={styles.pointersRow}>
					<Col xs={24} sm={24} md={12} className={styles.pointersContainerCol}>
						<div>
							<FiChevronsRight className={styles.icon}/>
							<p>
								All programs approved by All India Council of Technical Education, Delhi (Ministry of Education, Govt of India)
							</p>
						</div>
						
						<div>
							<FiChevronsRight className={styles.icon}/>
							<p>
								100% guaranteed placement assistance
							</p>
						</div>

						<div>
							<FiChevronsRight className={styles.icon}/>
							<p>
								Board of directors comprises professional super specialist doctors & academicians of national & international repute
							</p>
						</div>
						
						<div>
							<FiChevronsRight className={styles.icon}/>
							<p>
								Academic mentors providing training to enrolled students who are CEOs of top corporates in India and abroad
							</p>
						</div>
					</Col>

					<Col xs={24} sm={24} md={12} className={styles.pointersContainerCol}>
					<div>
							<FiChevronsRight className={styles.icon}/>
							<p>
								Promoting organization successfully running professional educational institutions since 39 years
							</p>
						</div>
						
						<div>
							<FiChevronsRight className={styles.icon}/>
							<p>
								Tie ups with prominent recruiters of India and abroad
							</p>
						</div>

						<div>
							<FiChevronsRight className={styles.icon}/>
							<p>
								Partnership with industry giants offering unique opportunities to our passouts
							</p>
						</div>
						
						<div>
							<FiChevronsRight className={styles.icon}/>
							<p>
								Contribute to research, innovation, and social impact initiatives
							</p>
						</div>

					</Col>
				</Row>
			</div>

			{/* Programs */}
			<div className={styles.programsContainer}>
				<div>
					<h1 className={styles.programsHeading}>Courses</h1>
				</div>
				<Row gutter={[70,70]} className="container-fluid">
						{programs.map((program, i) => (
							<Col key={i} xs={24} sm={24} md={12} lg={12}>
								<ProgramCard title={program.title} description={program.description} />
								{/* <Card hoverable title={program.title}>{program.description}</Card> */}
							</Col>
						))}
				</Row>
				{/* <div style={{marginBottom: "20px"}}></div>
				<Row gutter={[70,70]} className="container-fluid">
						{programs.map(program => (
							<Col xs={24} sm={24} md={12} lg={12}>
								<Card hoverable title={program.title}>{program.description}</Card>
							</Col>
						))}
				</Row> */}
			</div> 
		</div>
		</>
	)
}

function ProgramCard(props) {
	const title = props.title;
	const description = props.description;
	return (
		<div className={styles.card}>
			<h1>{title}</h1>
			{/* <Divider /> */}
			<p>{description}</p>
		</div>
	)
}

export default Homepage;